@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);

body {
  margin: 0;
  font-family: 'Inter';
  height: 100%;
}


.grecaptcha-badge {
  visibility: hidden;
}
